// React Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import LinkTwo from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Adapify
import gtag from '../Utilities/Gtag';

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: '30px 60px 20px',
    height: 60,
    [theme.breakpoints.down(1040)]: {
      marginLeft: '6%',
    },
    [theme.breakpoints.down(1150)]: {
      marginTop: '6.5%',
      marginBottom: '3%',
    },
  },
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  dockerAppearance: {
    display: 'none',
    '& .MuiPaper-root': {
      width: '30%',
      [theme.breakpoints.down(1150)]: {
        width: '45%',
      },
    },
    [theme.breakpoints.down(1150)]: {
      display: 'flex',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
    [theme.breakpoints.down(1150)]: {
      maxHeight: 91,
    },
  },
  menuIcon: {
    display: 'none',
    [theme.breakpoints.down(1150)]: {
      display: 'flex',
      marginLeft: '2%',
      marginTop: '2%',
      marginBottom: '1%',
    },
    // [theme.breakpoints.down(1150)]: {
    //   display: 'flex',
    //   marginLeft: '2%',
    //   marginTop: '1%',
    //   marginBottom: '1%',
    // },
    [theme.breakpoints.down(1150)]: {
      display: 'flex',
      marginLeft: '2%',
      marginTop: '2%',
      marginBottom: '2%',
    },
    [theme.breakpoints.down(600)]: {
      display: 'flex',
      marginLeft: '4%',
      marginTop: '2.5%',
      marginBottom: '2%',
    },
  },
  toolbarTitle: {
    flexGrow: 1,
    [theme.breakpoints.down(1150)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  navLinks: {
    marginRight: 60,
    [theme.breakpoints.down(1150)]: {
      width: '100%',
      margin: 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  homeLink: {
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: 18,
    float: 'left',
    color: '#A19DAF',
    justifySelf: 'center',
    textTransform: 'capitalize',
    paddingTop: '6px',
    margin: theme.spacing(1, 2),
    '&:hover': {
      textDecoration: 'none',
      color: '#777',
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none',
    },
  },
  testingLink: {
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: 18,
    color: '#A19DAF',
    justifySelf: 'center',
    textTransform: 'capitalize',
    margin: theme.spacing(1, 2),
    '&:hover': {
      textDecoration: 'none',
      color: '#777',
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none',
    },
  },
  link: {
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: 18,
    color: '#A19DAF',
    justifySelf: 'center',
    textTransform: 'capitalize',
    margin: theme.spacing(1, 3),
    '&:hover': {
      textDecoration: 'none',
      color: '#777',
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none',
    },
  },
  dropMenuLink: {
    fontWeight: 500,
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.68)',
    justifySelf: 'center',
    textTransform: 'capitalize',
    margin: theme.spacing(1, 3),
    '&:hover': {
      textDecoration: 'none',
      color: '#777',
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none',
    },
  },
  dropMenuHeaders: {
    fontWeight: 300,
    fontStyle: 'italic',
    fontSize: 16,
    color: '#5e5e5e',
    justifySelf: 'center',
    textTransform: 'capitalize',
    margin: theme.spacing(1, 3),
    '&:hover': {
      textDecoration: 'none',
      color: '#5e5e5e',
    },
    [theme.breakpoints.down(1150)]: {
      display: 'none',
    },
  },
  headerButton: {
    marginLeft: 15,
  },
  isDisabled: {
    cursor: 'default',
    textDecoration: 'none',
    position: 'relative',
    marginRight: '-25px',
    left: '-30px',
    top: '-15px',
    color: '#de2031',
    fontWeight: '600',
  },
  servicesDropDownIcon: {
    paddingBottom: '1px',
  },
}));

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleTopServiceClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTopServiceClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Alert className={classes.mainAlert} severity="error" variant="filled">
          <strong>Important Announcement:</strong>
          <br />
          <br />
          Due the unexpected passing of an essential team member, we regret to inform you that we
          {' '}
          are no longer accepting any new orders at this time. We will make every effort to fulfill
          {' '}
          existing orders but know that our customer service responses will be delayed. Thank you
          {' '}
          for your understanding during this difficult time.
          <br />
          <br />
        </Alert>
        <Toolbar className={classes.toolbar}>
          <div style={{
            display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center',
          }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              style={{ width: '8%' }}
              className={classes.menuIcon}
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              <a href="/">
                <img
                  className={classes.logo}
                  src={`${process.env.PUBLIC_URL}/logo-wide.webp`}
                  alt="logo"
                />
              </a>
            </Typography>
            <Drawer
              className={classes.dockerAppearance}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <div>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem button component={Link} to="/" key="Home">
                  <ListItemText
                    key="Home"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Home Link Clicked',
                        event_label: 'Header - Home Link Clicked',
                        value: 'Header - Home Link Clicked',
                      });
                    }}
                  >
                    Home
                  </ListItemText>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button component={Link} to="/nutrients" key="Nutrient Analysis">
                  <ListItemText
                    key="Nutrient Analysis"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Nutrients Link Clicked',
                        event_label: 'Header - Nutrients Link Clicked',
                        value: 'Header - Nutrients Link Clicked',
                      });
                    }}
                  >
                    Nutrient Analysis
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/texture" key="Texture">
                  <ListItemText
                    href="/texture"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Texture Link Clicked',
                        event_label: 'Header - Texture Link Clicked',
                        value: 'Header - Texture Link Clicked',
                      });
                    }}
                  >
                    Texture Analysis
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/metals" key="Heavy Metals">
                  <ListItemText
                    href="/metals"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Metals Link Clicked',
                        event_label: 'Header - Metals Link Clicked',
                        value: 'Header - Metals Link Clicked',
                      });
                    }}
                  >
                    Lead &#38; Metals
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/pesticides" key="Complete Package">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Pesticides Link Clicked',
                        event_label: 'Header - Pesticides Link Clicked',
                        value: 'Header - Pesticides Link Clicked',
                      });
                    }}
                  >
                    Pesticides
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/microorganisms" key="Microorganisms">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Microorganisms Link Clicked',
                        event_label: 'Header - Microorganisms Link Clicked',
                        value: 'Header - Microorganisms Link Clicked',
                      });
                    }}
                  >
                    Biology
                  </ListItemText>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button component={Link} to="/packages">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Packages Link Clicked',
                        event_label: 'Header - Packages Link Clicked',
                        value: 'Header - Packages Link Clicked',
                      });
                    }}
                  >
                    Complete Package
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/partner">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Partner Link Clicked',
                        event_label: 'Header - Partner Link Clicked',
                        value: 'Header - Partner Link Clicked',
                      });
                    }}
                  >
                    Partner
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/app">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - ViewMyResults Clicked',
                        event_label: 'Header - ViewMyResults Clicked',
                        value: 'Header - ViewMyResults Clicked',
                      });
                    }}
                  >
                    Login/Register
                  </ListItemText>
                </ListItem>
              </List>
            </Drawer>
          </div>
          <nav className={classes.navLinks}>
            <Button aria-controls="services-menu" aria-haspopup="true" className={classes.link} onClick={handleTopServiceClick}>
              Testing Services
              {' '}
              <DropDownIcon className={classes.servicesDropDownIcon} />
            </Button>
            <LinkTwo
              variant="button"
              href="/"
              className={classes.homeLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Home Link Clicked',
                  event_label: 'Header - Home Link Clicked',
                  value: 'Header - Home Link Clicked',
                });
              }}
            >
              Home
            </LinkTwo>
            <Menu
              id="services-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={Boolean(anchorEl)}
              onClose={handleTopServiceClose}
            >
              <span className={classes.dropMenuHeaders}>Basic Testing</span>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  style={{ color: 'primary' }}
                  href="/nutrients"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Nutrients Link Clicked',
                      event_label: 'Header - Nutrients Link Clicked',
                      value: 'Header - Nutrients Link Clicked',
                    });
                  }}
                >
                  Nutrient Analysis
                </LinkTwo>
              </MenuItem>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/texture"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Texture Link Clicked',
                      event_label: 'Header - Texture Link Clicked',
                      value: 'Header - Texture Link Clicked',
                    });
                  }}
                >
                  Texture Analysis
                </LinkTwo>
              </MenuItem>
              <span className={classes.dropMenuHeaders}>Contaminants</span>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/metals"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Metals Link Clicked',
                      event_label: 'Header - Metals Link Clicked',
                      value: 'Header - Metals Link Clicked',
                    });
                  }}
                >
                  Lead &#38; Metals
                </LinkTwo>
              </MenuItem>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/pesticides"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Pesticides Link Clicked',
                      event_label: 'Header - Pesticides Link Clicked',
                      value: 'Header - Pesticides Link Clicked',
                    });
                  }}
                >
                  Pesticides
                </LinkTwo>
              </MenuItem>
              <span className={classes.dropMenuHeaders}>Advanced</span>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/microorganisms"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Microorganisms Link Clicked',
                      event_label: 'Header - Microorganisms Link Clicked',
                      value: 'Header - Microorganisms Link Clicked',
                    });
                  }}
                >
                  Microbiology
                </LinkTwo>
              </MenuItem>
            </Menu>
            <LinkTwo
              variant="button"
              href="/packages"
              className={classes.testingLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Packages Link Clicked',
                  event_label: 'Header - Packages Link Clicked',
                  value: 'Header - Packages Link Clicked',
                });
              }}
            >
              Complete Package
            </LinkTwo>
            <LinkTwo
              variant="button"
              href="https://partner.rxsoil.com"
              className={classes.testingLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Partner Link Clicked',
                  event_label: 'Header - Partner Link Clicked',
                  value: 'Header - Partner Link Clicked',
                });
              }}
            >
              Partner
            </LinkTwo>
            <LinkTwo
              variant="button"
              href="https://app.rxsoil.com"
              className={classes.testingLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - ViewMyResults Clicked',
                  event_label: 'Header - ViewMyResults Clicked',
                  value: 'Header - ViewMyResults Clicked',
                });
              }}
            >
              Login/Register
            </LinkTwo>
          </nav>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
